<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Agregar Rol - {{user.name}} </ion-title>
    <ion-form>
      <ion-select v-model="role_id" placeholder="Seleccione el rol" >
        <ion-select-option v-for="role in roles" :key="role" :value="role.id">{{role.name}} </ion-select-option>
      </ion-select>

      <ion-button color="dark" @click="postRol">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonSelect } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  components :{IonSelect},
  props:['user'],
  setup() {
    return { close }
  },
  data(){
    return{
      roles : []
    }
  },
  mounted(){
    this.getRoles()
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    async getRoles(){
   
      axios.get('/api/roles?all=true')
      .then(res =>{
        this.roles = res.data.data
      }).catch(err =>{
        console.log(err)
      })
    },
   async postRol(){
      var loading = await toast.showLoading()

      await loading.present();

      axios.post('/api/users/roles',{
        user_id : this.user.id,
        role_id : this.role_id
      })
      .then(data =>{
        this.getRoles();
        loading.dismiss()
         this.closeModal()
        this.$toast.openToast("Asignacion de rol exitoso","success")
        console.log(data)
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>

